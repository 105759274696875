<div class="d-flex align-items-center">
    <div class="flex-fill">
        <h5 *ngIf="!checkoutDetail.payAnnually" class="text-black font-weight-bolder mb-0">Monthly Charges</h5>
        <h5 *ngIf="checkoutDetail.payAnnually" class="text-black font-weight-bolder mb-0">Annual Charges</h5>
    </div>

    <div>
        <ng-container *ngIf="calculatingTotal">
            <mat-spinner diameter="26" value="accent" class="float-right white-spinner"></mat-spinner>
        </ng-container>
        <span class="" *ngIf="!calculatingTotal">
      <h5 *ngIf="!checkoutDetail.payAnnually" class=" m-0">
        {{checkoutDetail.monthlyTotal | formatMoneyValue}}</h5>
      <h5 *ngIf="checkoutDetail.payAnnually" class=" m-0">
        {{(checkoutDetail.monthlyTotal * 12 * 0.8) | formatMoneyValue}}</h5>
    </span>
    </div>
</div>